import React from 'react'

export default function VsCode() {
    return (
        <iframe src="https://github1s.com/vivek9patel/vivek9patel.github.io/blob/HEAD/src/components/ubuntu.js" frameBorder="0" title="VsCode" className="h-full w-full bg-ub-cool-grey"></iframe>
        // this is not my work, but it's amazing!
        // Here is the link to the original repo: https://github.com/conwnet/github1s
    )
}

export const displayVsCode = () => {
    <VsCode> </VsCode>
}
